import { EditorSDK, PageData, PanelResolveType, RouterRef } from '@wix/platform-editor-sdk';
import type { PageRoleId } from '@wix/pricing-plans-router-utils';
import type { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import pricingPlans from '../../.application.json';
import pricingPlansIllustration from '../assets/images/pricing-plans.png';
import { splitPagesMigration } from './installPages';

const appDefinitionId = pricingPlans.appDefinitionId;
const TOKEN = '';
const ROUTER_PREFIX = 'pricing-plans';

// Allows to identify page by it's purpose/role instead of requiring to use page IDs which are unique per site.
const PackagePickerPageRole = 'PricingPlans' as const;
const CheckoutPageRole = 'Checkout' as const;
const ThankYouPageRole = 'ThankYou' as const;
const PaywallPageRole = 'Paywall' as const;

export async function installRouter(sdk: EditorSDK, connectPaywall = false) {
  let routerRef = await sdk.document.routers.getByPrefix(TOKEN, { prefix: ROUTER_PREFIX });
  const pages = await sdk.document.pages.getApplicationPages(TOKEN);
  if (!routerRef) {
    routerRef = await sdk.document.routers.add(TOKEN, { prefix: ROUTER_PREFIX, config: { pages } });
    await connectAllPagesToRouter(sdk, routerRef, pages, connectPaywall);
  } else {
    const routerData = await sdk.document.routers.get(TOKEN, { routerRef });
    if (routerData.prefix !== ROUTER_PREFIX) {
      await sdk.document.routers.update(TOKEN, { routerRef, prefix: ROUTER_PREFIX });
    }
    const installedPageRoles = routerData.pages.flatMap((page) => page.pageRoles);
    if (!installedPageRoles.includes(PackagePickerPageRole)) {
      await connectPackagePickerPageToRouter(sdk, routerRef, pages);
    }
    if (!installedPageRoles.includes(CheckoutPageRole)) {
      await connectCheckoutPageToRouter(sdk, routerRef);
    }
    if (!installedPageRoles.includes(ThankYouPageRole)) {
      await connectThankYouPageToRouter(sdk, routerRef);
    }
    if (connectPaywall && !installedPageRoles.includes(PaywallPageRole)) {
      await connectPaywallPageToRouter(sdk, routerRef);
    }
  }
}

export async function uninstallRouter(sdk: EditorSDK) {
  const routerRef = await sdk.document.routers.getByPrefix(TOKEN, { prefix: ROUTER_PREFIX });
  if (routerRef) {
    await sdk.document.routers.remove(TOKEN, { routerRef });
  }
}

function findUri(pages: PageData[], tpaPageId: string, fallback: string): string {
  return pages.find((page) => page.tpaPageId === tpaPageId)?.pageUriSEO ?? fallback;
}

async function connectAllPagesToRouter(
  sdk: EditorSDK,
  routerRef: RouterRef,
  pages: PageData[],
  connectPaywall = false,
) {
  await connectPackagePickerPageToRouter(sdk, routerRef, pages);
  await connectCheckoutPageToRouter(sdk, routerRef);
  await connectThankYouPageToRouter(sdk, routerRef);
  if (connectPaywall) {
    await connectPaywallPageToRouter(sdk, routerRef);
  }
}

const connectThankYouPageToRouter = async (sdk: EditorSDK, routerRef: RouterRef) =>
  connectPageToRouter('Thank You', 'thank_you', sdk, routerRef);

const connectCheckoutPageToRouter = async (sdk: EditorSDK, routerRef: RouterRef) =>
  connectPageToRouter('Checkout', 'checkout', sdk, routerRef);

const connectPaywallPageToRouter = async (sdk: EditorSDK, routerRef: RouterRef) =>
  connectPageToRouter('Paywall', 'paywall', sdk, routerRef);

const connectPackagePickerPageToRouter = async (sdk: EditorSDK, routerRef: RouterRef, pages: PageData[]) =>
  connectPageToRouter(
    'membership_plan_picker_tpa',
    'pricing_plans',
    sdk,
    routerRef,
    findUri(pages, 'membership_plan_picker_tpa', 'plans'),
  );

async function connectPageToRouter(
  tpaPageId: string,
  pageRole: PageRoleId,
  sdk: EditorSDK,
  routerRef: RouterRef,
  slug?: string,
) {
  await sdk.document.routers.pages.connect(TOKEN, {
    routerRef,
    pageRef: await sdk.document.tpa.getPageRefByTPAPageId(TOKEN, { tpaPageId }),
    pageRoles: [pageRole],
    // @ts-expect-error
    slug,
  });
}

export async function suggestMigratingToRouter(flowAPI: EditorScriptFlowAPI, sdk: EditorSDK) {
  const t = flowAPI.translations.t;
  await sdk.editor
    .openPromotionalPanel('', {
      illustration: pricingPlansIllustration,
      titleText: t('es.migrate-to-router.modal.title'),
      subtitleText: t('es.migrate-to-router.modal.subtitle'),
      mainActionText: t('es.migrate-to-router.modal.mainAction'),
      contentArray: [t('es.migrate-to-router.modal.bullet-1'), t('es.migrate-to-router.modal.bullet-2')],
      KBLinkText: t('es.migrate-to-router.modal.link-text'),
      helpId: '', // TODO: add article id
    })
    .then(async (result) => {
      switch (result) {
        case PanelResolveType.MAIN_ACTION:
          await migrateToRouter(flowAPI, sdk);
          break;
      }
    });
}

async function migrateToRouter(flowAPI: EditorScriptFlowAPI, sdk: EditorSDK): Promise<void> {
  flowAPI.fedops.interactionStarted('router_migraton_event');
  const t = flowAPI.translations.t;
  await sdk.editor.openProgressBar('', { totalSteps: 3, title: t('es.migrate-to-router.progress.title') });
  await sdk.editor.updateProgressBar('', { currentStep: 1, stepTitle: t('es.migrate-to-router.steps.1') });
  await splitPagesMigration(flowAPI, sdk);

  const pages = await sdk.pages.data.getAll(TOKEN);
  const ppPage = pages.find((page) => page.managingAppDefId === appDefinitionId && page.id);
  if (ppPage) {
    const prefix = ppPage.pageUriSEO;
    await sdk.pages.data.update(TOKEN, {
      pageRef: await sdk.document.components.getById(TOKEN, { id: ppPage?.id! }),
      data: { ...ppPage, pageUriSEO: `${prefix}-statics` },
    });

    await sdk.editor.updateProgressBar('', { currentStep: 2, stepTitle: t('es.migrate-to-router.steps.2') });
    const isValidPrefix = (await sdk.routers.isValidPrefix('', { prefix })).valid;
    if (isValidPrefix) {
      const appPages = await sdk.pages.getApplicationPages(TOKEN);
      const routerRef = await sdk.routers.add(TOKEN, { prefix, config: { pages: appPages } });
      await sdk.editor.updateProgressBar('', { currentStep: 3, stepTitle: t('es.migrate-to-router.steps.3') });
      await connectAllPagesToRouter(sdk, routerRef, appPages);
      flowAPI.fedops.interactionEnded('router_migraton_event');
      await sdk.editor.closeProgressBar('', { isError: false });
    }
  } else {
    await sdk.editor.closeProgressBar('', { isError: true });
  }
}
