import { ElementCategoryData, ElementData } from '@wix/platform-editor-sdk';
import { FlowAPI } from '@wix/yoshi-flow-editor';
import { SinglePlanWidgetRole } from '../../constants/elements';

enum CategoryId {
  Info = 'info',
  Benefits = 'benefits',
}

export const getCategoriesData = (t: FlowAPI['translations']['t']): ElementCategoryData[] => [
  {
    id: CategoryId.Info,
    title: t('blocks.elements-panel.info'),
  },
  {
    id: CategoryId.Benefits,
    title: t('blocks.elements-panel.benefits'),
  },
];

export const getElementsData = (t: FlowAPI['translations']['t']): ElementData[] => {
  const elements = [
    {
      label: t('blocks.elements-panel.image'),
      identifier: { role: SinglePlanWidgetRole.ImageContainer },
      categoryId: CategoryId.Info,
      tooltipData: {
        content: t('blocks.elements-panel.image-tooltip'),
      },
    },
    {
      label: t('blocks.elements-panel.badge'),
      identifier: { role: SinglePlanWidgetRole.RibbonWidget },
      categoryId: CategoryId.Info,
    },
    {
      label: t('blocks.elements-panel.plan-name'),
      identifier: { role: SinglePlanWidgetRole.PlanName },
      categoryId: CategoryId.Info,
    },
    {
      label: t('blocks.elements-panel.price'),
      identifier: { role: SinglePlanWidgetRole.PricingWidget },
      categoryId: CategoryId.Info,
    },
    {
      label: t('blocks.elements-panel.description'),
      identifier: { role: SinglePlanWidgetRole.Description },
      categoryId: CategoryId.Info,
    },
    {
      label: t('blocks.elements-panel.plan-duration'),
      identifier: { role: SinglePlanWidgetRole.PlanDuration },
      categoryId: CategoryId.Info,
    },
    {
      label: t('blocks.elements-panel.button'),
      identifier: { role: SinglePlanWidgetRole.Button },
      categoryId: CategoryId.Info,
    },
    {
      label: t('blocks.elements-panel.content-divider'),
      identifier: { role: SinglePlanWidgetRole.ContentDividerContainer },
      categoryId: CategoryId.Info,
    },
    {
      label: t('blocks.elements-panel.benefits-section'),
      identifier: { role: SinglePlanWidgetRole.PerksWidget },
      categoryId: CategoryId.Benefits,
    },
    {
      label: t('blocks.elements-panel.icon'),
      identifier: { role: SinglePlanWidgetRole.PerkIcon },
      categoryId: CategoryId.Benefits,
    },
    {
      label: t('blocks.elements-panel.perk-divider'),
      identifier: { role: SinglePlanWidgetRole.PerkDivider },
      categoryId: CategoryId.Benefits,
    },
  ];

  const SETUP_FEE_ELEMENT_INDEX = 4;

  elements.splice(SETUP_FEE_ELEMENT_INDEX, 0, {
    label: t('blocks.elements-panel.setup-fee'),
    identifier: { role: SinglePlanWidgetRole.SetupFee },
    categoryId: CategoryId.Info,
  });

  return elements.map((element, i) => ({ ...element, index: i }));
};
