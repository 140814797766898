import { EditorSDK } from '@wix/platform-editor-sdk';
import { ConnectedComponentsBuilder, EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { SINGLE_PLAN_HELP_ARTICLE_ID } from '../constants';
import { openPlanForm } from '../utils/widget';

export function makeUnselectable(builder: ConnectedComponentsBuilder) {
  builder.behavior().set({
    closed: {
      selectable: false,
      hideFromHierarchy: true,
    },
    preventHide: true,
  });
}

export function configureTextElement(params: {
  builder: ConnectedComponentsBuilder;
  flowAPI: EditorScriptFlowAPI;
  editorSDK: EditorSDK;
  designTextLabel: string;
  editTextLabel: string;
}) {
  const { builder, flowAPI, editorSDK, designTextLabel, editTextLabel } = params;
  builder
    .behavior()
    .set({ closed: { hideFromHierarchy: false, selectable: true }, dataEditOptions: 'TEXT_STYLE_ONLY' });

  builder
    .gfpp()
    .set('help', { id: SINGLE_PLAN_HELP_ARTICLE_ID })
    .set('connect', { behavior: 'HIDE' })
    .set('link', { behavior: 'HIDE' });

  if (flowAPI.environment.isEditorX) {
    builder
      .gfpp()
      .set('mainAction1', {
        onClick: (e) => openPlanForm({ editorSDK, flowAPI, componentRef: e.detail.componentRef }),
        label: editTextLabel,
      })
      .set('mainAction2', { behavior: 'HIDE' });
  } else {
    builder
      .gfpp()
      .set('mainAction1', {
        label: designTextLabel,
        onClick: (e) => {
          editorSDK.editor.openNativeComponentPanel('', 'settings', {
            componentRef: e.detail.componentRef,
            helpId: SINGLE_PLAN_HELP_ARTICLE_ID,
          });
        },
      })
      .set('mainAction2', {
        onClick: (e) => openPlanForm({ editorSDK, flowAPI, componentRef: e.detail.componentRef }),
        label: editTextLabel,
      });
    builder
      .gfpp('mobile')
      .set('mainAction1', {
        label: designTextLabel,
        onClick: (e) => {
          editorSDK.editor.openNativeComponentPanel('', 'settings', {
            componentRef: e.detail.componentRef,
            helpId: SINGLE_PLAN_HELP_ARTICLE_ID,
          });
        },
      })
      .set('settings', { behavior: 'HIDE' })
      .set('mainAction2', { behavior: 'HIDE' });
  }
}
