import { pickBy } from 'lodash';
import { Alignment, ComponentRef, EditorSDK } from '@wix/platform-editor-sdk';
import { SinglePlanWidgetRole } from '../../../../constants/elements';
import { findComponentByRole } from '../../../../utils/widget';
import { ElementSizeData } from '../../../Plan/panels/Layout/utils';

type CurrencyAlignment = Extract<Alignment, 'start' | 'center' | 'end'>;
export type PricingDesignVariables = {
  currencyAlignment: CurrencyAlignment;
  currencyPosition: 2 | 4;
  pricePosition: 2 | 4;
  currencySpacing: ElementSizeData<'px'>;
  currencyTopMargin: ElementSizeData<'px'>;
  currencyBottomMargin: ElementSizeData<'px'>;
};

export async function getPricingDesignVariables(editorSDK: EditorSDK | null, componentRef: ComponentRef) {
  const variables = await editorSDK?.document.application.appStudioWidgets.designVariables.get('', {
    widgetRef: componentRef,
  });
  return variables as PricingDesignVariables;
}

export async function getCurrencyMargin(params: {
  editorSDK: EditorSDK;
  priceRef: ComponentRef;
  currencyRef: ComponentRef;
}) {
  const { editorSDK, priceRef, currencyRef } = params;
  const [priceStyle, currencyStyle] = await Promise.all([
    getParsedFontProperties(editorSDK, priceRef),
    getParsedFontProperties(editorSDK, currencyRef),
  ]);
  if (!priceStyle || !currencyStyle) {
    return;
  }

  const priceHeight = priceStyle.fontSizePx * priceStyle.lineHeightEm;
  const currencyHeight = currencyStyle.fontSizePx * currencyStyle.lineHeightEm;
  if (priceHeight <= currencyHeight) {
    return 0;
  }

  /*
    Descender height is a part of the font that goes below the baseline.
    Since the price is always on the baseline, descender gap is always blank.
    We need to add the descender height as the margin for the currency, so it is aligned with the price.
    Using approximated ratio of descender height, which would work for most fonts.
    In other cases, user can adjust the margin manually.
  */
  const APPROX_DESCENDER_RATIO = 0.13;

  const priceDescenderGap = priceStyle.fontSizePx * APPROX_DESCENDER_RATIO;
  const priceLineHeightGap = (priceHeight - priceStyle.fontSizePx) / 2;
  const priceGap = priceDescenderGap + priceLineHeightGap;

  const currencyDescenderGap = currencyStyle.fontSizePx * APPROX_DESCENDER_RATIO;
  const currencyLineHeightGap = (currencyHeight - currencyStyle.fontSizePx) / 2;
  const currencyGap = currencyDescenderGap + currencyLineHeightGap;

  const finalGap = Math.round(priceGap - currencyGap);
  return Math.max(finalGap, 0);
}

async function getParsedFontProperties(
  editorSDK: EditorSDK,
  componentRef: ComponentRef,
): Promise<{ fontSizePx: number; lineHeightEm: number } | null> {
  const style = await editorSDK.document.components.style.get('', { componentRef });
  const { fontSize, lineHeight } = style.style.properties;
  const fontSizeNumber = parseInt(fontSize, 10);
  const lineHeightNumber = lineHeight === 'normal' ? 1.2 : parseFloat(lineHeight);

  if (isNaN(fontSizeNumber) || isNaN(lineHeightNumber)) {
    return null;
  }

  return {
    fontSizePx: fontSizeNumber,
    lineHeightEm: lineHeightNumber,
  };
}

export function setPricingDesignVariables(params: {
  editorSDK: EditorSDK | null;
  widgetRef: ComponentRef;
  newValues: Partial<PricingDesignVariables>;
}) {
  const { editorSDK, widgetRef, newValues } = params;
  const definedNewValues = pickBy(newValues, (value) => value !== undefined);
  return editorSDK?.document.application.appStudioWidgets.designVariables.set('', {
    widgetRef,
    newValues: definedNewValues,
  });
}

export async function updateCurrencyMargins(params: {
  editorSDK: EditorSDK;
  priceRef?: ComponentRef;
  currencyRef?: ComponentRef;
  widgetRef: ComponentRef;
}) {
  const { editorSDK, widgetRef } = params;
  const [priceRef, currencyRef] = await Promise.all([
    params.priceRef ?? findComponentByRole({ editorSDK, controllerRef: widgetRef, role: SinglePlanWidgetRole.Price }),
    params.currencyRef ??
      findComponentByRole({ editorSDK, controllerRef: widgetRef, role: SinglePlanWidgetRole.Currency }),
  ]);
  if (!priceRef || !currencyRef) {
    return;
  }
  const currentDesignVariables = await getPricingDesignVariables(editorSDK, widgetRef);

  const newValues: Pick<PricingDesignVariables, 'currencyTopMargin' | 'currencyBottomMargin'> = {
    currencyTopMargin: currentDesignVariables.currencyTopMargin,
    currencyBottomMargin: currentDesignVariables.currencyBottomMargin,
  };

  const updatedMargin = await getCurrencyMargin({ editorSDK, priceRef, currencyRef });
  switch (currentDesignVariables.currencyAlignment) {
    case 'center':
      newValues.currencyTopMargin = { unit: 'px', value: 0 };
      newValues.currencyBottomMargin = { unit: 'px', value: 0 };
      break;

    case 'start':
      if (updatedMargin !== undefined) {
        newValues.currencyTopMargin = {
          unit: 'px',
          value: updatedMargin,
        };
        newValues.currencyBottomMargin = {
          unit: 'px',
          value: 0,
        };
      }
      break;

    case 'end':
      if (updatedMargin !== undefined) {
        newValues.currencyBottomMargin = {
          unit: 'px',
          value: updatedMargin,
        };
        newValues.currencyTopMargin = {
          unit: 'px',
          value: 0,
        };
      }
      break;
  }

  return setPricingDesignVariables({
    editorSDK,
    widgetRef,
    newValues,
  });
}
