import { ComponentRef, EditorSDK } from '@wix/platform-editor-sdk';
import { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { PlanListWidgetRole } from '../../constants/elements';
import { openElementsPanel } from '../../utils/open-elements-panel';
import { getRootWidget } from '../../utils/widget';
import PlanWidget from '../Plan/.component.json';
import { openDisclaimerLayoutPanel } from './panels/DisclaimerLayout/open-disclaimer-layout-panel';
import { openPlanListLayoutPanel } from './panels/Layout/open-plan-list-layout-panel';
import { openPlanSettingsPanel } from './panels/PlanSettings/open-plan-settings-panel';
import { openPlanListSettingsPanel } from './panels/Settings/open-plan-list-settings-panel';
import { openSubtitleLayoutPanel } from './panels/SubtitleLayout/open-subtitle-layout-panel';
import { openTitleLayoutPanel } from './panels/TitleLayout/open-title-layout-panel';

const UNSELECTABLE_ELEMENTS = [
  PlanListWidgetRole.PlansInfoState,
  PlanListWidgetRole.PlanList,
  PlanListWidgetRole.PlanListStates,
  PlanListWidgetRole.Header,
  PlanListWidgetRole.TitleContainer,
  PlanListWidgetRole.DisclaimerContainer,
  PlanListWidgetRole.PlanVariantDefaultState,
  PlanListWidgetRole.PlanVariantHighlightedState,
  PlanListWidgetRole.PlanVariantCustomState,
  PlanListWidgetRole.PlanVariantDefaultWidget,
  PlanListWidgetRole.PlanVariantHighlightedWidget,
  PlanListWidgetRole.PlanVariantCustomWidget,
  PlanListWidgetRole.PlanListItem,
  PlanListWidgetRole.PlanVariantBox,
];

export const getWidgetManifest: GetWidgetManifestFn = (builder, editorSDK, flowAPI) => {
  builder.set({ displayName: 'Plan List' });

  builder
    .gfpp()
    .set('mainAction1', {
      onClick: (e) => openPlanListSettingsPanel({ editorSDK, flowAPI, componentRef: e.detail.componentRef }),
      label: flowAPI.translations.t('blocks.plan-list-settings.title'),
    })
    .set('add', {
      onClick: (e) => openPlanListElementsPanel({ editorSDK, flowAPI, widgetRef: e.detail.componentRef }),
    })
    .set('layout', {
      onClick: (e) => openPlanListLayoutPanel({ editorSDK, componentRef: e.detail.componentRef }),
    });

  builder.behavior().set({ resizable: true, duplicatable: true });

  UNSELECTABLE_ELEMENTS.forEach((element) =>
    builder.configureConnectedComponents(element, (elementBuilder) => {
      elementBuilder.behavior().set({ closed: { hideFromHierarchy: true, selectable: false } });
    }),
  );

  builder.configureConnectedComponents(PlanListWidgetRole.Title, (disclaimerBuilder) => {
    disclaimerBuilder
      .gfpp()
      .set('layout', {
        onClick: async (e) =>
          openTitleLayoutPanel({ editorSDK, componentRef: await getRootWidget(editorSDK, e.detail.componentRef) }),
      })
      .set('connect', { behavior: 'HIDE' });
  });

  builder.configureConnectedComponents(PlanListWidgetRole.SubtitleContainer, (headerBuilder) => {
    headerBuilder.behavior().set({ closed: { selectable: false, hideFromHierarchy: true } });
  });

  builder.configureConnectedComponents(PlanListWidgetRole.Subtitle, (disclaimerBuilder) => {
    disclaimerBuilder
      .gfpp()
      .set('layout', {
        onClick: async (e) =>
          openSubtitleLayoutPanel({ editorSDK, componentRef: await getRootWidget(editorSDK, e.detail.componentRef) }),
      })
      .set('connect', { behavior: 'HIDE' });
  });

  builder.configureConnectedComponents(PlanListWidgetRole.Disclaimer, (disclaimerBuilder) => {
    disclaimerBuilder
      .gfpp()
      .set('layout', {
        onClick: async (e) =>
          openDisclaimerLayoutPanel({ editorSDK, componentRef: await getRootWidget(editorSDK, e.detail.componentRef) }),
      })
      .set('connect', { behavior: 'HIDE' });
  });

  builder.configureConnectedComponents(PlanListWidgetRole.PlanListItemContainer, (planListItemBuilder) => {
    planListItemBuilder.set({ displayName: flowAPI.translations.t('blocks.plan-list.plan-label') });
    planListItemBuilder.behavior().set({ closed: { selectable: true, hideFromHierarchy: false } });
    planListItemBuilder
      .gfpp()
      .set('mainAction1', {
        label: flowAPI.translations.t('blocks.plan-list.plan.settings.title'),
        onClick: (e) => openPlanSettingsPanel({ editorSDK, componentRef: e.detail.componentRef, flowAPI }),
      })
      .set('design', { target: { controllerType: PlanWidget.controllerId } })
      .set('layout', { target: { controllerType: PlanWidget.controllerId } })
      .set('add', { target: { controllerType: PlanWidget.controllerId } });
  });
};

function openPlanListElementsPanel(params: {
  editorSDK: EditorSDK;
  widgetRef: ComponentRef;
  flowAPI: EditorScriptFlowAPI;
}) {
  const { editorSDK, widgetRef, flowAPI } = params;
  const t = flowAPI.translations.t;
  return openElementsPanel({
    editorSDK,
    widgetRef,
    data: {
      categories: [
        {
          id: 'plan-info',
          title: t('blocks.plan-list-elements-panel.plan-info'),
        },
      ],
      elements: [
        {
          label: t('blocks.plan-list-elements-panel.title'),
          identifier: { role: PlanListWidgetRole.Title },
          categoryId: 'plan-info',
          index: 0,
        },
        {
          label: t('blocks.plan-list-elements-panel.subtitle'),
          identifier: { role: PlanListWidgetRole.Subtitle },
          categoryId: 'plan-info',
          index: 1,
        },
        {
          label: t('blocks.plan-list-elements-panel.plan-list'),
          identifier: { role: PlanListWidgetRole.PlanList },
          categoryId: 'plan-info',
          index: 2,
        },
        {
          label: t('blocks.plan-list-elements-panel.disclaimer'),
          identifier: { role: PlanListWidgetRole.Disclaimer },
          categoryId: 'plan-info',
          index: 3,
        },
      ],
    },
  });
}
