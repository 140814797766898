import { PRESETS_BY_STATE, WidgetState } from '../layout-config';

export type LayoutType = 'vertical' | 'horizontal';
export type ButtonPosition = 'center' | 'bottom';

export function layoutOptionsToWidgetState(layoutType: LayoutType, buttonPosition: ButtonPosition): WidgetState {
  const isBottomButton = buttonPosition === 'bottom';
  if (layoutType === 'horizontal') {
    return isBottomButton ? WidgetState.HorizontalWideButton : WidgetState.Horizontal;
  }

  return isBottomButton ? WidgetState.VerticalBottomButton : WidgetState.Default;
}

export function getLayoutType(state: WidgetState): LayoutType {
  return isHorizontalState(state) ? 'horizontal' : 'vertical';
}

export function presetIdToWidgetState(presetId?: string): WidgetState {
  if (!presetId || PRESETS_BY_STATE[WidgetState.Default].some(({ id }) => id === presetId)) {
    return WidgetState.Default;
  }

  if (PRESETS_BY_STATE[WidgetState.VerticalBottomButton].some(({ id }) => id === presetId)) {
    return WidgetState.VerticalBottomButton;
  }

  if (PRESETS_BY_STATE[WidgetState.Horizontal].some(({ id }) => id === presetId)) {
    return WidgetState.Horizontal;
  }

  if (PRESETS_BY_STATE[WidgetState.HorizontalWideButton].some(({ id }) => id === presetId)) {
    return WidgetState.HorizontalWideButton;
  }

  return WidgetState.Default;
}

export function isHorizontalState(state: WidgetState): boolean {
  return state === WidgetState.Horizontal || state === WidgetState.HorizontalWideButton;
}

export function getButtonPosition(state: WidgetState): ButtonPosition {
  if (state === WidgetState.VerticalBottomButton || state === WidgetState.HorizontalWideButton) {
    return 'bottom';
  }

  return 'center';
}
