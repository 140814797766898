import { PlanDesignVariables } from './components/Plan/panels/Layout/utils';
import { SinglePlanPresetId } from './constants/single-plan-preset';
import thumbnails from './layout-thumbnails';

export interface Preset {
  id: SinglePlanPresetId;
  width: number;
  height: number;
  thumbnailWidth: string;
  thumbnailHeight: string;
  thumbnailSrc: string;
  layoutDefaults: Pick<PlanDesignVariables, 'imageHeight'>;
}

export enum WidgetState {
  Default = 'default',
  VerticalBottomButton = 'vertical-bottom-button',
  Horizontal = 'horizontal',
  HorizontalWideButton = 'horizontal-wide-button',
}

export enum RootPresetId {
  Desktop = 'variants-l9mt9pib1',
  Mobile = 'variants-lbbv6kl4',
}

export const isValidPresetId = (id: string): id is SinglePlanPresetId => {
  return Object.values(SinglePlanPresetId).includes(id as SinglePlanPresetId);
};

export const PRESETS: Record<SinglePlanPresetId, Preset> = {
  [SinglePlanPresetId.vertical]: {
    id: SinglePlanPresetId.vertical,
    thumbnailWidth: '114px',
    thumbnailHeight: '258px',
    thumbnailSrc: thumbnails.vertical,
    width: 300,
    height: 200,
    layoutDefaults: {
      imageHeight: {
        unit: 'px',
        value: 160,
      },
    },
  },
  [SinglePlanPresetId.vertical_no_image]: {
    id: SinglePlanPresetId.vertical_no_image,
    thumbnailWidth: '114px',
    thumbnailHeight: '222px',
    thumbnailSrc: thumbnails.vertical_no_image,
    width: 300,
    height: 200,
    layoutDefaults: {
      imageHeight: {
        unit: 'px',
        value: 160,
      },
    },
  },
  [SinglePlanPresetId.vertical_tt_d]: {
    id: SinglePlanPresetId.vertical_tt_d,
    thumbnailWidth: '114px',
    thumbnailHeight: '222px',
    thumbnailSrc: thumbnails.vertical_tt_d,
    width: 300,
    height: 200,
    layoutDefaults: {
      imageHeight: {
        unit: 'percentage',
        value: 100,
      },
    },
  },
  [SinglePlanPresetId.vertical_fb]: {
    id: SinglePlanPresetId.vertical_fb,
    thumbnailWidth: '114px',
    thumbnailHeight: '259px',
    thumbnailSrc: thumbnails.vertical_fb,
    width: 300,
    height: 200,
    layoutDefaults: {
      imageHeight: {
        unit: 'px',
        value: 160,
      },
    },
  },
  [SinglePlanPresetId.vertical_fb_no_image]: {
    id: SinglePlanPresetId.vertical_fb_no_image,
    thumbnailWidth: '114px',
    thumbnailHeight: '222px',
    thumbnailSrc: thumbnails.vertical_fb_no_image,
    width: 304,
    height: 200,
    layoutDefaults: {
      imageHeight: {
        unit: 'px',
        value: 0,
      },
    },
  },
  [SinglePlanPresetId.vertical_fb_tt_d]: {
    id: SinglePlanPresetId.vertical_fb_tt_d,
    thumbnailWidth: '114px',
    thumbnailHeight: '222px',
    thumbnailSrc: thumbnails.vertical_fb_tt_d,
    width: 300,
    height: 200,
    layoutDefaults: {
      imageHeight: {
        unit: 'percentage',
        value: 100,
      },
    },
  },
  [SinglePlanPresetId.horizontal]: {
    id: SinglePlanPresetId.horizontal,
    thumbnailWidth: '240px',
    thumbnailHeight: '126px',
    thumbnailSrc: thumbnails.horizontal,
    width: 980,
    height: 410,
    layoutDefaults: {
      imageHeight: {
        unit: 'percentage',
        value: 100,
      },
    },
  },
  [SinglePlanPresetId.horizontal_tt_d]: {
    id: SinglePlanPresetId.horizontal_tt_d,
    thumbnailWidth: '240px',
    thumbnailHeight: '126px',
    thumbnailSrc: thumbnails.horizontal_tt_d,
    width: 980,
    height: 410,
    layoutDefaults: {
      imageHeight: {
        unit: 'percentage',
        value: 100,
      },
    },
  },
  [SinglePlanPresetId.horizontal_no_image]: {
    id: SinglePlanPresetId.horizontal_no_image,
    thumbnailWidth: '240px',
    thumbnailHeight: '126px',
    thumbnailSrc: thumbnails.horizontal_no_image,
    width: 560,
    height: 410,
    layoutDefaults: {
      imageHeight: {
        unit: 'percentage',
        value: 100,
      },
    },
  },

  [SinglePlanPresetId.horizontal_fb]: {
    id: SinglePlanPresetId.horizontal_fb,
    thumbnailWidth: '240px',
    thumbnailHeight: '124px',
    thumbnailSrc: thumbnails.horizontal_fb,
    width: 980,
    height: 410,
    layoutDefaults: {
      imageHeight: {
        unit: 'percentage',
        value: 100,
      },
    },
  },
  [SinglePlanPresetId.horizontal_fb_tt_d]: {
    id: SinglePlanPresetId.horizontal_fb_tt_d,
    thumbnailWidth: '240px',
    thumbnailHeight: '126px',
    thumbnailSrc: thumbnails.horizontal_fb_tt_d,
    width: 980,
    height: 410,
    layoutDefaults: {
      imageHeight: {
        unit: 'percentage',
        value: 100,
      },
    },
  },
  [SinglePlanPresetId.horizontal_fb_no_image]: {
    id: SinglePlanPresetId.horizontal_fb_no_image,
    thumbnailWidth: '240px',
    thumbnailHeight: '126px',
    thumbnailSrc: thumbnails.horizontal_fb_no_image,
    width: 560,
    height: 410,
    layoutDefaults: {
      imageHeight: {
        unit: 'percentage',
        value: 100,
      },
    },
  },
};

export const PRESETS_BY_STATE: Record<WidgetState, Preset[]> = {
  [WidgetState.Default]: [
    PRESETS[SinglePlanPresetId.vertical_tt_d],
    PRESETS[SinglePlanPresetId.vertical],
    PRESETS[SinglePlanPresetId.vertical_no_image],
  ],
  [WidgetState.VerticalBottomButton]: [
    PRESETS[SinglePlanPresetId.vertical_fb_tt_d],
    PRESETS[SinglePlanPresetId.vertical_fb],
    PRESETS[SinglePlanPresetId.vertical_fb_no_image],
  ],
  [WidgetState.Horizontal]: [
    PRESETS[SinglePlanPresetId.horizontal],
    PRESETS[SinglePlanPresetId.horizontal_tt_d],
    PRESETS[SinglePlanPresetId.horizontal_no_image],
  ],
  [WidgetState.HorizontalWideButton]: [
    PRESETS[SinglePlanPresetId.horizontal_fb],
    PRESETS[SinglePlanPresetId.horizontal_fb_tt_d],
    PRESETS[SinglePlanPresetId.horizontal_fb_no_image],
  ],
};

export const DEFAULT_PRESETS_BY_STATE: Record<WidgetState, Preset> = {
  [WidgetState.Default]: PRESETS[SinglePlanPresetId.vertical],
  [WidgetState.VerticalBottomButton]: PRESETS[SinglePlanPresetId.vertical_fb],
  [WidgetState.Horizontal]: PRESETS[SinglePlanPresetId.horizontal],
  [WidgetState.HorizontalWideButton]: PRESETS[SinglePlanPresetId.horizontal_fb],
};

export const MOBILE_PRESET_BY_DESKTOP_PRESET_ID: Record<SinglePlanPresetId, SinglePlanPresetId> = {
  [SinglePlanPresetId.vertical]: SinglePlanPresetId.vertical,
  [SinglePlanPresetId.vertical_no_image]: SinglePlanPresetId.vertical_no_image,
  [SinglePlanPresetId.vertical_tt_d]: SinglePlanPresetId.vertical_tt_d,
  [SinglePlanPresetId.vertical_fb]: SinglePlanPresetId.vertical_fb,
  [SinglePlanPresetId.vertical_fb_no_image]: SinglePlanPresetId.vertical_fb_no_image,
  [SinglePlanPresetId.vertical_fb_tt_d]: SinglePlanPresetId.vertical_fb_tt_d,

  [SinglePlanPresetId.horizontal]: SinglePlanPresetId.vertical,
  [SinglePlanPresetId.horizontal_no_image]: SinglePlanPresetId.vertical_no_image,
  [SinglePlanPresetId.horizontal_tt_d]: SinglePlanPresetId.vertical_tt_d,
  [SinglePlanPresetId.horizontal_fb_tt_d]: SinglePlanPresetId.vertical_fb_tt_d,
  [SinglePlanPresetId.horizontal_fb]: SinglePlanPresetId.vertical_fb,
  [SinglePlanPresetId.horizontal_fb_no_image]: SinglePlanPresetId.vertical_fb_no_image,
};
