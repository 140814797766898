export enum SinglePlanPresetId {
  vertical = 'variants-l8wxqpaw',
  vertical_no_image = 'variants-l9gups7t',
  vertical_tt_d = 'variants-l60nfem1',
  vertical_fb = 'variants-l60lthp5',
  vertical_fb_no_image = 'variants-l9mlptiv',
  vertical_fb_tt_d = 'variants-l9mlmpkm',
  horizontal = 'variants-l5upxns2',
  horizontal_no_image = 'variants-l9mlr37s',
  horizontal_tt_d = 'variants-l6ai5twg',
  horizontal_fb = 'variants-l64qe8a4',
  horizontal_fb_no_image = 'variants-l9mlsuqi',
  horizontal_fb_tt_d = 'variants-l9mm2pv1',
}
